import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import Icon from "./components/Social/Icon";

import Header from "./components/Template/Header";

function App() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [settings, setSettings] = useState([]);
  useEffect(() => {
    const savedTitle = localStorage.getItem("site_title");
    if (savedTitle) {
      document.title = savedTitle;
    }

    fetchSettings();
  }, [API_BASE_URL]);

  async function fetchSettings() {
    try {
      const resp = await fetch(API_BASE_URL + "/api/settings");
      const settingsResp = await resp.json();

      const namedSettings = settingsResp.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});

      setSettings(namedSettings);

      if (settingsResp.length) {
        localStorage.setItem("site_title", namedSettings["site_title"]);

        document.title = namedSettings["site_title"];
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [settings, setSettings] = useState([]);

  return (
    <div>
      <h2>Home</h2>
      <Container fluid>
        <Row>
          <Col>
            <p>Welcome to my website</p>
          </Col>
        </Row>
      </Container>

      <div>
        {Object.entries(settings).map(([key, value]) => (
          <div key={key}>
            <span>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function About() {
  return <h2>About</h2>;
}

function Gallery() {
  return <h2>Gallery</h2>;
}

export default App;
